// Generated by @nuxtjs/svg-sprite
export const icons = [
  "icons/clarityhashtagline",
  "icons/clarityhashtagsolid",
  "icons/eosiconsloading",
  "icons/404",
  "icons/fluentmention16filled",
  "icons/fluentmdl2world",
  "icons/fluentsettopstack16filled",
  "icons/fluentsettopstack16regular",
  "icons/gravityuibars",
  "icons/gravityuichevronleft",
  "icons/gravityuiperson",
  "icons/gravityuipersonslock",
  "icons/gravityuixmark",
  "icons/heroiconsphoto",
  "icons/materialsymbolsarrowbackiosrounded",
  "icons/ionarrowbacksharp",
  "icons/materialsymbolsarrowbackrounded",
  "icons/materialsymbolsarrowforwardiosrounded",
  "icons/materialsymbolssearch",
  "icons/mdieyeoffoutline",
  "icons/mdieyeoutline",
  "icons/mingcutecodeline",
  "icons/mingcutemenuline",
  "icons/mingcuteexitdoorfill",
  "icons/mingcutemore3fill",
  "icons/phplugsconnectedfill",
  "icons/mingcutesettings5fill",
  "icons/mingcutemore3line",
  "icons/solartrashbinminimalisticbold",
  "icons/add",
  "icons/uilenvelopealt",
  "icons/arrow-right",
  "icons/blocked",
  "icons/clarity--administrator-line",
  "icons/eos-icons--loading",
  "icons/bx--repost",
  "icons/file-lines",
  "icons/fluent--mention-16-filled",
  "icons/edit",
  "icons/fenxiang",
  "icons/gengduo",
  "icons/guanzhu-2",
  "icons/left",
  "icons/hide",
  "icons/lets-icons--favorite-fill",
  "icons/ic--outline-report",
  "icons/lets-icons--favorite",
  "icons/logo",
  "icons/material-symbols--bookmark-add-outline-rounded",
  "icons/logo-bak",
  "icons/material-symbols--bookmark-added-rounded",
  "icons/material-symbols--globe",
  "icons/material-symbols-light--globe-asia-sharp",
  "icons/mingcute--comment-line",
  "icons/mingcute--sitemap-line",
  "icons/more",
  "icons/mingcute--user-follow-2-line",
  "icons/ph--image-bold",
  "icons/pinglun",
  "icons/selected",
  "icons/tabler--check",
  "icons/shuqian",
  "icons/vote",
  "icons/xihuan2",
  "icons/zhuanfa",
  "icons/xihuan",
  "icons/zondicons--repost",
  "icons/humbleicons--share",
  "icons/guanzhu"
]
