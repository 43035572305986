import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    isShowModal: false,
    showModalType: ''
  }),
  actions: {
    // 打开修改密码弹窗
    openChangePwdModal() {
      this.isShowModal = true
      this.showModalType = 'changePwd'
    },

    // 关闭弹窗
    closeModal() {
      this.isShowModal = false
      this.showModalType = ''
    }
  }
})

