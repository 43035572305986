const productConfig = {
  chainId: 56,
  FACTORY_ADDR: '0xA36B49084550fE388c7B81A103699ea315242563',
  INIT_CODE_HASH: '0xb594b680ac8ffbcd817033d8b9a0f18807d93811006cb8a3ad1884015bb00ea0',
  WEB3_URL: 'https://bsc-testnet-dataseed.bnbchain.org',
  WEB3_BROWSERURL: 'https://testnet.bscscan.com',
  WEB3_GETEHTPRICE_URL: 'https://api-testnet.bscscan.com/api?module=stats&action=bnbprice', 
  currencyList: [
    {
      id: 1,
      currencyName: 'GNC',
      icon: '',
      name: 'good news coin',
      addr: '0x9d52F981a05304C8595D27a3799BC890b2DEa0C1',
    },
    {
      id: 2,
      currencyName: 'USDT',
      icon: 'cryptocurrency-color:usdt',
      name: 'USDT',
      addr: '0x55d398326f99059ff775485246999027b3197955',
    },
    {
      id: 3,
      currencyName: 'BNB',
      icon: 'cryptocurrency-color:bnb',
      name: 'BNB',
      addr: '0x4967C30aCcAb17C098407858eA3d87eBA154AFD2',
    },
  ],
};

const devConfig = {
  chainId: 97,
  FACTORY_ADDR: '0xA36B49084550fE388c7B81A103699ea315242563',
  INIT_CODE_HASH: '0xb594b680ac8ffbcd817033d8b9a0f18807d93811006cb8a3ad1884015bb00ea0',
  WEB3_URL: 'https://bsc-testnet-dataseed.bnbchain.org',
  WEB3_BROWSERURL: 'https://testnet.bscscan.com',
  WEB3_GETEHTPRICE_URL: 'https://api-testnet.bscscan.com/api?module=stats&action=bnbprice',
  currencyList: [
    {
      id: 1,
      currencyName: 'GNC',
      icon: '',
      name: 'good news coin',
      addr: '0xd32E0e71C985384e6E78407eE0420C12994c3147',
    },
    {
      id: 2,
      currencyName: 'USDT',
      icon: 'cryptocurrency-color:usdt',
      name: 'USDT',
      addr: '0x169A30BC6f0EE6cAB97Ac65D8C2CAe89634e9B68',
    },
    {
      id: 3,
      currencyName: 'BNB',
      icon: 'cryptocurrency-color:bnb',
      name: 'BNB',
      addr: '0x4967C30aCcAb17C098407858eA3d87eBA154AFD2',
    },
  ],
};

const testConfig = { ...devConfig };
const configMap = {
  dev: devConfig,
  test: testConfig,
  prod: productConfig,
  preprod: productConfig,
};

const webConfig = configMap[import.meta.env.VITE_ENV] || devConfig;

export default webConfig;
